import Blocks from '@/containers/blocks'
// import * as navigation from '@/lib/mock/navigation'

const props = {
  blocks: [
    {
      __component: 'blocks.banner-static',
      image: {
        src: 'https://mclaren-ecommerce.ams3.cdn.digitaloceanspaces.com/images/Error_Banner.jpg',
        alt: 'pit stop'
      },
      content: `
      <h1>404 PAGE NOT FOUND</h1>
      <p>The page you were looking for may have been moved, removed, renamed or might have never existed </p>
      `,
      contentWhite: true,
      button: {
        title: 'Back to Home',
        url: '/'
      }
    },
    {
      __component: 'blocks.newsletter',
      title: 'JOIN McLAREN PLUS FOR FREE. GET 10% OFF WHEN YOU JOIN'
    }
  ]
}

export async function getStaticProps(context) {
  // const res = await fetch(`${process.env.NEXT_PUBLIC_API}/fixed-pages/homepage`)
  // const props = await res.json()
  return { props, revalidate: parseInt(process.env.REVALIDATE_PAGE) }
}

export default Blocks
